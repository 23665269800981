.search {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 400px;
    z-index: 10;
}

#create-location-modal {
    z-index: 10;
    position: absolute;
    width: 400;
    border: '2px solid #000';
    top: 500;
    left: 500;
}

.Map {
    flex: 1;
    height: 100%;
    position: relative;
}

#filter-form {
    position:absolute;
    top: 1rem;
    right: 1rem;
    z-index: 11;
    max-width: 15rem;
}