.rug-handle {
    visibility: hidden;
    height: 0;
    width: 0;
    position: absolute;
}

.rug-dragging-item {
    z-index: 2000;
}

/* div:first-child {
    visibility: hidden;
    height: 0;
    width: 0;
    position: absolute;
} */